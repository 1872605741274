import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import { routes, adminRoutes } from './config/constants'
import { PrivateRoute, LoginRoute, Login2FaRoute } from './components/PrivateRoute/PrivateRoute.js'
import AuthContext from './contexts/authContext'

// MDW MSP ROUTES
import Dashboard from './pages/Dashboard/DashboardContainer.jsx'
import News from './pages/News/NewsContainer.jsx'
import Customers from './pages/Customers/CustomersContainer.jsx'
import AddCustomer from './pages/AddCustomer/AddCustomerContainer.jsx'
import Login from './pages/Login/LoginContainer.jsx'
import Account from './pages/Account/AccountContainer.jsx'
import HelpdeskAlerting from './pages/HeldeskAlerting/HelpdeskAlertingContainer.jsx'
import Branding from './pages/Branding/BrandingContainer'
import BreachCatalogue from './pages/BreachCatalogue/BreachCatalogueContainer'
import BreachDetails from './pages/BreachDetails/BreachDetailsContainer'
import CustomerDetails from './pages/CustomerDetails/CustomerDetailsContainer'
import FeatureRequests from './pages/FeatureRequests/FeatureRequestsContainer'
import Integrations from './pages/Integrations/IntegrationsContainer'
import Users from './pages/Users/UsersContainer'
import ProspectingReport from './pages/ProspectingReport/ProspectingReportContainer'
import PreviousSearches from './pages/PreviousSearches/PreviousSearchesContainer'
import Breaches from './pages/Breaches/BreachesContainer'
import FirstBreaches from './pages/FirstBreaches/FirstBreachesContainer'
// MDW ADMIN ROUTES
import AdminDashboard from './pages/AdminDashboard/AdminDashboardContainer'
import AdminMSPs from './pages/AdminMSPs/AdminMSPsContainer'
import AdminPackages from './pages/AdminPackages/AdminPackagesContainer'
import AdminFeatures from './pages/AdminFeatures/AdminFeaturesContainer'
import AdminBreachCatalogue from './pages/AdminBreachCatalogue/AdminBreachCatalogueContainer'
import AdminBreachDetails from './pages/AdminBreachDetails/AdminBreachDetailsContainer'
import AdminAddMSP from './pages/AdminAddMSP/AdminAddMSPContainer'
import AdminSubscriptions from './pages/AdminSubscriptions/AdminSubscriptionsContainer'
import AdminPendingMSPs from './pages/AdminPendingMSPs/AdminPendingMSPsContainer'
import AdminNews from './pages/AdminNews/AdminNewsContainer'
import AdminAddNews from './pages/AdminAddNews/AdminAddNewsContainer'
import AdminReports from './pages/AdminReports/AdminReportsContainer'
import TwoFA from './pages/TwoFA/TwoFAContainer'
import ForgotPassword from './pages/ForgotPassword/ForgotPasswordContainer'
import ResetPassword from './pages/ResetPassword/ResetPasswordContainer'
import AdminUsers from './pages/AdminUsers/adminMspUser'
import AdminAdmin from './pages/AdminAdmin/AdminAdminContainer'
import Admin2FARequest from './pages/Admin2FARequest/Admin2FARequestContainer'
import AdminDashboardContent from './pages/AdminDashboardContent/AdminDashboardContentContainer'
import AdminTrainingMaterial from './pages/AdminTrainingMaterial/AdminTrainingMaterialContainer'
import TrainingMaterial from './pages/TrainingMaterial/TrainingMaterialContainer'
import AdminProspecting from './pages/AdminResetProspecting/ResetProspectingContainer'
import CommunityContainer from './pages/Community/CommunityContainer'
export default function App() {
  return (
    <AuthContext>
      <Router>
        <Switch>
          {/* MDW MSP ROUTES */}
          <LoginRoute exact path={routes.LOGIN_ROUTE} component={Login} />
          <Login2FaRoute exact path={routes.TWOFA_ROUTE} component={TwoFA} />
          <LoginRoute exact path={routes.FORGOT_PASSWORD_ROUTE} component={ForgotPassword} />
          <Route exact path={routes.RESET_PASSWORD_ROUTE + '/:token'} component={ResetPassword} />
          <PrivateRoute
            exact
            path={routes.DASHBOARD_ROUTE}
            component={Dashboard}
            roles={['MSP', 'MSP_USER']}
          />
          <PrivateRoute
            exact
            path={routes.NEWS_ROUTE}
            component={News}
            roles={['MSP', 'MSP_USER']}
          />
          <PrivateRoute
            exact
            path={routes.CUSTOMERS_ROUTE}
            component={Customers}
            roles={['MSP', 'MSP_USER']}
          />
          <PrivateRoute
            exact
            path={routes.ADD_CUSTOMER_ROUTE}
            component={AddCustomer}
            roles={['MSP', 'MSP_USER']}
          />
          <PrivateRoute
            exact
            path={routes.CUSTOMERS_ROUTE + '/:id'}
            component={CustomerDetails}
            roles={['MSP', 'MSP_USER']}
          />
          <PrivateRoute
            exact
            path={routes.ACCOUNT_ROUTE}
            component={Account}
            roles={['MSP', 'MSP_USER']}
          />
          <PrivateRoute
            exact
            path={routes.HELPDESK_ALERTING_ROUTE}
            component={HelpdeskAlerting}
            roles={['MSP', 'MSP_USER']}
          />
          <PrivateRoute
            exact
            path={routes.BRANDIND_ROUTE}
            component={Branding}
            roles={['MSP', 'MSP_USER']}
          />
          <PrivateRoute
            exact
            path={routes.BREACHES_ROUTE}
            component={Breaches}
            roles={['MSP', 'MSP_USER']}
          />
          <PrivateRoute
            exact
            path={routes.BREACH_CATALOGUE_ROUTE}
            component={BreachCatalogue}
            roles={['MSP', 'MSP_USER']}
          />
          <PrivateRoute
            exact
            path={routes.BREACH_DETAILS_ROUTE}
            component={BreachDetails}
            roles={['MSP', 'MSP_USER']}
          />
          <PrivateRoute
            exact
            path={routes.FEATURE_REQUESTS_ROUTE}
            component={FeatureRequests}
            roles={['MSP', 'MSP_USER']}
          />
          <PrivateRoute
            exact
            path={routes.COMMUNITY_ROUTE}
            component={CommunityContainer}
            roles={['MSP', 'MSP_USER']}
          />
          <PrivateRoute
            exact
            path={routes.INTEGRATIONS_ROUTE}
            component={Integrations}
            roles={[]}
          />
          <PrivateRoute
            exact
            path={routes.USERS_ROUTE}
            component={Users}
            roles={['MSP', 'MSP_USER']}
          />
          <PrivateRoute
            exact
            path={routes.USERS_ROUTE + '/:id'}
            component={Users}
            roles={['MSP', 'MSP_USER']}
          />
          <PrivateRoute
            exact
            path={routes.PROSPECTING_REPORT_ROUTE}
            component={ProspectingReport}
            roles={['MSP', 'MSP_USER']}
          />
          <PrivateRoute
            exact
            path={routes.PREVIOUS_SEARCHES_ROUTE}
            component={PreviousSearches}
            roles={['MSP', 'MSP_USER']}
          />
          <PrivateRoute
            exact
            path={routes.FIRST_MSP_BREACH_ROUTE}
            component={FirstBreaches}
            roles={['MSP', 'MSP_USER']}
          />
          <PrivateRoute
            exact
            path={routes.FIRST_BREACH_ROUTE}
            component={FirstBreaches}
            roles={['MSP', 'MSP_USER']}
          />
          <PrivateRoute
            exact
            path={routes.TRAINING_MATERIAL_ROUTE}
            component={TrainingMaterial}
            roles={['MSP', 'MSP_USER']}
          />
          {/* MDW ADMIN ROUTES */}
          <PrivateRoute
            exact
            path={adminRoutes.DASHBOARD_ROUTE}
            component={AdminDashboard}
            roles={['MDW']}
          />
          <PrivateRoute
            exact
            path={adminRoutes.RESET_PROSPECTING_ROUTE}
            component={AdminProspecting}
            roles={['MDW']}
          />
          <PrivateRoute exact path={adminRoutes.MSPS_ROUTE} component={AdminMSPs} roles={['MDW']} />
          <PrivateRoute
            exact
            path={adminRoutes.PACKAGES_ROUTE}
            component={AdminPackages}
            roles={['MDW']}
          />
          <PrivateRoute
            exact
            path={adminRoutes.PACKAGES_ROUTE + '/:id'}
            component={AdminPackages}
            roles={['MDW']}
          />
          <PrivateRoute
            exact
            path={adminRoutes.FEATURES_ROUTE}
            component={AdminFeatures}
            roles={['MDW']}
          />
          <PrivateRoute
            exact
            path={adminRoutes.BREACH_CATALOGUE_ROUTE}
            component={AdminBreachCatalogue}
            roles={['MDW']}
          />
          <PrivateRoute
            exact
            path={adminRoutes.BREACH_DETAILS_ROUTE}
            component={AdminBreachDetails}
            roles={['MDW']}
          />
          <PrivateRoute
            exact
            path={adminRoutes.PENDING_MSPS_ROUTE}
            component={AdminPendingMSPs}
            roles={['MDW']}
          />
          <PrivateRoute
            exact
            path={adminRoutes.ADD_MSP_ROUTE}
            component={AdminAddMSP}
            roles={['MDW']}
          />
          <PrivateRoute
            exact
            path={adminRoutes.EDIT_MSP_ROUTE + ':id'}
            component={AdminAddMSP}
            roles={['MDW']}
          />
          <PrivateRoute
            exact
            path={adminRoutes.SUBSCRIPTIONS_ROUTE}
            component={AdminSubscriptions}
            roles={['MDW']}
          />
          <PrivateRoute exact path={adminRoutes.NEWS_ROUTE} component={AdminNews} roles={['MDW']} />
          <PrivateRoute
            exact
            path={adminRoutes.ADD_NEWS_ROUTE}
            component={AdminAddNews}
            roles={['MDW']}
          />
          <PrivateRoute
            exact
            path={adminRoutes.REPORTS_ROUTE}
            component={AdminReports}
            roles={['MDW']}
          />
          <PrivateRoute
            exact
            path={adminRoutes.USERS_ROUTE}
            component={AdminUsers}
            roles={['MDW']}
          />
          <PrivateRoute
            exact
            path={adminRoutes.USERS_ROUTE + '/:id'}
            component={AdminUsers}
            roles={['MDW']}
          />
          <PrivateRoute
            exact
            path={adminRoutes.ADMIN_ROUTE}
            component={AdminAdmin}
            roles={['MDW']}
          />
          <PrivateRoute
            exact
            path={adminRoutes.TWOFA_REQUESTS_ROUTE}
            component={Admin2FARequest}
            roles={['MDW']}
          />
          <PrivateRoute
            exact
            path={adminRoutes.DASHBOARD_CONTENT_ROUTE}
            component={AdminDashboardContent}
            roles={['MDW']}
          />
          <PrivateRoute
            exact
            path={adminRoutes.TRAINING_MATERIAL_ROUTE}
            component={AdminTrainingMaterial}
            roles={['MDW']}
          />
        </Switch>
      </Router>
    </AuthContext>
  )
}
