import MspMenu from '../../components/MspMenu'
import AccountComponent from './AccountComponent.jsx'
import { useState, useEffect } from 'react'
import { updateMyMspService, getMyMspService } from '../../services/msp.service'
import { mspErrorHandler } from './errorHandler'
import { useFormik } from 'formik'
import { schema } from '../../schemas/mspEdit.schema'
import { notAllowedDomains, routes } from '../../config/constants'
import { resetFirstBreachService } from '../../services/firstBreach.service'
import { getAllPackagesService } from '../../services/package.service'
import { addPendingSubscriptionService } from '../../services/pendingSubscription.service'

const AccountContainer = () => {
  const [showModal, setShowModal] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [table, setTable] = useState([])
  const [packages, setPackages] = useState([])
  const [packageSelected, setPackageSelected] = useState({
    package: '',
  })
  const [showAccountUpgradeModal, setShowAccountUpgradeModal] = useState(false)
  const [initialValues, setInitialValues] = useState({
    companyName: '',
    street: '',
    city: '',
    postalCode: '',
    phoneNumber: '',
    website: '',
    ip:'',
    pcFirstName: '',
    pcLastName: '',
    pcEmail: '',
    pcTelephone: '',
    invoiceDelivery: '',
    domains: [],
    emails: [],
    billingFirstName: '',
    billingLastName: '',
    billingEmail: '',
    billingTelephone: '',
    availableDomains:0,
    packageId: '',
  })

  async function getMyMSPFromApi() {
    try {
      const response = await getMyMspService()
      const myMSP = response.data.data

      let domains = myMSP.domains.filter(domain => domain.customerId === null)
      let emails = myMSP.emails.filter(email => email.customerId === null)

      setInitialValues({
        companyName: myMSP.companyName,
        street: myMSP.street,
        city: myMSP.city,
        postalCode: myMSP.postalCode,
        phoneNumber: myMSP.phoneNumber,
        website: myMSP.website,
        ip: myMSP.ip,
        pcFirstName: myMSP.pcFirstName,
        pcLastName: myMSP.pcLastName,
        pcEmail: myMSP.pcEmail,
        pcTelephone: myMSP.pcTelephone,
        invoiceDelivery: myMSP.invoiceDelivery,
        domains: domains.map(domain => domain.domain),
        emails: emails.map(email => email.email),
        billingFirstName: myMSP.billingFirstName,
        billingLastName: myMSP.billingLastName,
        billingEmail: myMSP.billingEmail,
        availableDomains: (myMSP?.package?.packageDomains || 0) - (myMSP?.domains?.length || 0),
        billingTelephone: myMSP.billingTelephone,
      })

      domains = domains.map(domain => ({ value: domain.domain, new: false }))
      emails = emails.map(email => ({ value: email.email, new: false }))

      setTable([...domains, ...emails])
    } catch (error) {
      setShowModal(mspErrorHandler(error))
    }
  }

  async function getAllPackagesFromAPI() {
    try {
      const response = await getAllPackagesService(true)
      setPackages(response.data.data)
    } catch (error) {
      setShowModal(mspErrorHandler(error))
    }
  }

  useEffect(() => {
    getAllPackagesFromAPI()
    getMyMSPFromApi()
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: async (data, actions) => {
      try {
        setIsLoading(true)
        await updateMyMspService(data)
        setShowModal({
          show: true,
          title: 'MSP HAS BEEN UPDATED',
          message: 'The MSP has been updated successfully',
          redirect: routes.ACCOUNT_ROUTE,
        })
        actions.resetForm()
        getMyMSPFromApi()
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
        setShowModal(mspErrorHandler(error))
      }
    },
  })

  const handleClickDelete = (selectedString, id) => {
    setTable(table.filter(emailOrDomain => emailOrDomain.value !== selectedString))
    if (id === 'emails') {
      setInitialValues(prevState => ({
        ...prevState,
        emails: formik.values.emails.filter(email => email !== selectedString),
      }))
    } else if (id === 'domains') {
      setInitialValues(prevState => ({
        ...prevState,
        domains: formik.values.domains.filter(domain => domain !== selectedString),
      }))
    }
  }

  function handleOnClickAdd(id) {
    const value = document.getElementById(id).value

    if (id === 'domains') {
      if (initialValues.domains.includes(value)) {
        setShowModal({
          show: true,
          title: 'PLEASE PUT AN OTHER DOMAIN TO SEARCH',
          message: `You entered "${value}" this domain is already added`,
        })
        document.getElementById(id).value = ''
      } else {
        if (
          !/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/.test(value)
        ) {
          setShowModal({
            show: true,
            title: 'PLEASE PUT A VALID DOMAIN TO SEARCH',
            message: `You entered "${value}" which is not a valid domain`,
          })
          document.getElementById(id).value = ''
        } else if (notAllowedDomains.includes(value)) {
          setShowModal({
            show: true,
            title: 'PLEASE PUT A VALID DOMAIN TO SEARCH',
            message: `The domain ${value} is in a list of disallowed domains`,
          })
          document.getElementById(id).value = ''
        } else {
          setTable(prevState => [...prevState, { value, new: true }])
          if (value !== '')
            setInitialValues(prevState => ({ ...prevState, [id]: [...prevState[id], value] }))
          document.getElementById(id).value = ''
        }
      }
    } else {
      if (initialValues.emails.includes(value)) {
        setShowModal({
          show: true,
          title: 'PLEASE PUT AN OTHER EMAIL TO SEARCH',
          message: `You entered "${value}" this email is already added`,
        })
        document.getElementById(id).value = ''
      } else {
        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
          setShowModal({
            show: true,
            title: 'PLEASE PUT A VALID EMAIL TO SEARCH',
            message: `You entered "${value}" which is not a valid email`,
          })
          document.getElementById(id).value = ''
        } else {
          setTable(prevState => [...prevState, { value, new: true }])
          if (value !== '')
            setInitialValues(prevState => ({ ...prevState, [id]: [...prevState[id], value] }))
          document.getElementById(id).value = ''
        }
      }
    }
  }

  async function handleResetDomainOrEmail(domainOrEmail) {
    try {
      await resetFirstBreachService(domainOrEmail)
      setShowModal({
        show: true,
        title: 'HAS BEEN SUCCESSFULLY UPDATED',
        message: `The initial scan of ${domainOrEmail} has been updated successfully`,
      })
    } catch (error) {
      setShowModal(mspErrorHandler(error))
    }
  }

  function handleClickUpgradeAccount() {
    setShowAccountUpgradeModal(true)
  }

  function handleCloseAccountUpgradeModal() {
    setShowAccountUpgradeModal(false)
  }

  function handleChangePackageRequest(e) {
    const { value } = e.target
    setPackageSelected({ package: value })
  }

  async function handleSubmitPackageRequest() {
    if (packageSelected.package === '') return
    try {
      await addPendingSubscriptionService(packageSelected)
      setShowModal({
        show: true,
        title: 'SUCCESSFULLY RECEIVED',
        message: 'Your package change request has been received',
      })
      setPackageSelected({
        package: '',
      })
    } catch (error) {
      setShowModal(mspErrorHandler(error))
    }
  }

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <>
      <MspMenu>
        <AccountComponent
          formik={formik}
          isLoading={isLoading}
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          handleOnClickAdd={handleOnClickAdd}
          table={table}
          handleResetDomainOrEmail={handleResetDomainOrEmail}
          handleClickDelete={handleClickDelete}
          handleClickUpgradeAccount={handleClickUpgradeAccount}
          showAccountUpgradeModal={showAccountUpgradeModal}
          handleChangePackageRequest={handleChangePackageRequest}
          handleSubmitPackageRequest={handleSubmitPackageRequest}
          packageSelected={packageSelected}
          packages={packages}
          handleCloseAccountUpgradeModal={handleCloseAccountUpgradeModal}
        />
      </MspMenu>
    </>
  )
}

export default AccountContainer
