import { useState, useEffect } from 'react'
import MspMenu from '../../components/MspMenu'
import {
  updateLightMspLogoService,
  deleteLightMspLogoService,
  updateMyMspService,
  updateDarkMspLogoService,
  deleteDarkMspLogoService,
  getMyMspLightLogoService,
} from '../../services/msp.service'
import { getMyUserService } from '../../services/user.service'
import { getAllCustomersService } from '../../services/customer.service'
import BrandingComponent from './BrandingComponent.jsx'
import { brandingErrorHandler } from './errorHandler'
import { useFormik } from 'formik'
import { schema } from '../../schemas/branding.schema'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import * as Yup from 'yup'
import { sendATestMonthlyReport } from '../../services/breach.service'

const BrandingContainer = () => {
  const [lightImg, setLightImg] = useState('')
  const [lightLogo, setLightLogo] = useState(null)
  const [darkImg, setDarkImg] = useState('')
  const [darkLogo, setDarkLogo] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState({})
  const [initialValues, setInitialValues] = useState({
    customerEmailSubject: '',
    customerEmailBody: '',
    customerEmailBodyCleanBill: '',
  })
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [editorStateCleanBill, setEditorStateCleanBill] = useState(EditorState.createEmpty())
  const [customers, setCustomers] = useState([])

  const onEditorStateChange = newEditorState => {
    setEditorState(newEditorState)
  }

  const onEditorStateCleanBillChange = newEditorState => {
    setEditorStateCleanBill(newEditorState)
  }

  async function getMyMSPFromAPI() {
    try {
      const response = await getMyUserService()
      const accountData = response.data.data
      if (accountData.msp?.lightLogo) {
        setLightImg(accountData.msp?.lightLogo)
      }
      if (accountData.msp?.darkLogo) {
        setDarkImg(accountData.msp?.darkLogo)
      }

      const { customerEmailSubject, customerEmailBody, customerEmailBodyCleanBill } =
        accountData.msp
      setInitialValues({ customerEmailSubject })

      const contentBlock = htmlToDraft(customerEmailBody)
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
        setEditorState(EditorState.createWithContent(contentState))
      }

      const contentBlockCleanBill = htmlToDraft(customerEmailBodyCleanBill)
      if (contentBlockCleanBill) {
        const contentState = ContentState.createFromBlockArray(contentBlockCleanBill.contentBlocks)
        setEditorStateCleanBill(EditorState.createWithContent(contentState))
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function getAllCustomersFromAPI() {
    try {
      const response = await getAllCustomersService()
      const customers = response.data.data
      setCustomers(customers)
    } catch (error) {
      setShowModal(customersErrorHandler(error))
    }
  }

  useEffect(() => {
    getMyMSPFromAPI()
    getAllCustomersFromAPI()
  }, [])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: async (data, actions) => {
      try {
        setIsLoading(true)
        data.customerEmailBody = draftToHtml(convertToRaw(editorState.getCurrentContent()))
        data.customerEmailBodyCleanBill = draftToHtml(
          convertToRaw(editorStateCleanBill.getCurrentContent())
        )
        await updateMyMspService(data, true)
        setShowModal({
          show: true,
          title: 'SUCCESSFUL UPDATE',
          message: 'The reports information has been updated',
        })
        setIsLoading(false)
        getMyMSPFromAPI()
      } catch (error) {
        setIsLoading(false)
        console.log(error)
      }
    },
  })

  const formikTest = useFormik({
    initialValues: { customer: '', emailToSend: '' },
    validationSchema: Yup.object({
      customer: Yup.string().required().min(32),
      emailToSend:Yup.string().matches(
        /^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})((,| ,|, | , |){1}[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/gm,
        `Entered Emails are not valid, Please remove the exrta comma's and validate the entered mails again.`
      ),
    }),
    onSubmit: async (data, actions) => {
      try {
        await sendATestMonthlyReport(data)
        setShowModal({
          show: true,
          title: 'SUCCESSFUL SEND TEST',
          message: 'The monthly report test has been sent',
        })
        actions.resetForm()
      } catch (error) {
        console.log(error)
      }
    },
  })

  function onChangeLightImg(e) {
    if (e.target.files[0]) {
      setLightImg(URL.createObjectURL(e.target.files[0]))
      setLightLogo(e.target.files[0])
    }
  }

  async function onDeleteLightImg() {
    setLightImg('')
    setLightLogo(null)
    try {
      await deleteLightMspLogoService()
      localStorage.removeItem('logo')
      setTimeout(() => {
        location.reload()
      }, 3000)
    } catch (error) {
      console.log(error)
    }
  }

  async function handleSubmitLightLogo() {
    try {
      if (lightLogo) {
        setIsLoading(true)
        await updateLightMspLogoService({ lightLogo })
        setIsLoading(false)
        setShowModal({
          show: true,
          title: 'SUCCESSFUL UPLOAD',
          message: 'Your new logo has been uploaded successfully',
        })
        const logoResponse = await getMyMspLightLogoService()
        const logoUrl = logoResponse.data.data.lightLogo
        localStorage.setItem('logo', logoUrl)
        setTimeout(() => {
          location.reload()
        }, 3000)
      }
    } catch (error) {
      setIsLoading(false)
      setShowModal(brandingErrorHandler(error))
    }
  }

  function onChangeDarkImg(e) {
    if (e.target.files[0]) {
      setDarkImg(URL.createObjectURL(e.target.files[0]))
      setDarkLogo(e.target.files[0])
    }
  }

  async function onDeleteDarkImg() {
    setDarkImg('')
    setDarkLogo(null)
    try {
      await deleteDarkMspLogoService()
      setTimeout(() => {
        location.reload()
      }, 3000)
    } catch (error) {
      console.log(error)
    }
  }

  async function handleSubmitDarkLogo() {
    try {
      if (darkLogo) {
        setIsLoading(true)
        await updateDarkMspLogoService({ darkLogo })
        setIsLoading(false)
        setShowModal({
          show: true,
          title: 'SUCCESSFUL UPLOAD',
          message: 'Your new logo has been uploaded successfully',
        })
      }
    } catch (error) {
      setIsLoading(false)
      setShowModal(brandingErrorHandler(error))
    }
  }

  function handleClickVariable(e) {
    const variable = e.target.innerHTML
    setInitialValues({
      ...formik.values,
      customerEmailSubject: formik.values.customerEmailSubject + ' ' + variable,
    })
  }

  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <>
      <MspMenu>
        <BrandingComponent
          {...{
            handleClickVariable,
            editorState,
            onEditorStateChange,
            editorStateCleanBill,
            onEditorStateCleanBillChange,
            formik,
            lightImg,
            onChangeLightImg,
            onDeleteLightImg,
            handleSubmitLightLogo,
            darkImg,
            onChangeDarkImg,
            onDeleteDarkImg,
            handleSubmitDarkLogo,
            isLoading,
            showModal,
            handleCloseModal,
            customers,
            formikTest,
          }}
        />
      </MspMenu>
    </>
  )
}

export default BrandingContainer
