import * as Yup from 'yup'
import { industries, countries } from '../config/constants.js'

function isValidEmails (message) {
  return this.test("isValidMails", message, function (value) {
    const { path, createError } = this;
    let emails = value.split(",")
    const areEmailsValid = (emails) =>{
      for (let email of emails){if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/.test(email.trim())){continue}
      return (false)}
      return (true)
  }
    if (areEmailsValid(emails) === false) {
      return createError({ path, message: message ?? `REQUIRED_ERROR_MESSAGE` });
    }
    return true;
  });
}

Yup.addMethod(Yup.mixed, "isvalidMail", isValidEmails)

export const schema = Yup.object({
  companyName: Yup.string()
    .min(2, 'Company name must be at least 2 chars long')
    .max(50, 'The company name must have a maximum of 50 characters')
    .required('Company name is required'),
  firstName: Yup.string()
    .min(2, 'First name must be at least 2 chars long')
    .max(20, 'First name must have a maximum of 20 characters')
    .required('First name is required'),
  lastName: Yup.string()
    .min(2, 'Last name must be at least 2 chars long')
    .max(20, 'Last name must have a maximum of 20 characters')
    .required('Last name is required'),
  // reportEmail: Yup.string().email('Must be a valid email').required('Report email is required'),
  
  // reportEmail: Yup.string().matches(
  //   /^(\w([A-Za-z0-9-_+.]*\w+)+@(\w(-*\w+)+\.)+[a-zA-Z]{1,9}[,;])*\w([A-Za-z0-9-_+.']*\w+)+@(\w(-*\w+)+\.)+[a-zA-Z]{1,9}$/gm,
  //   `The entered email address(s) are not valid`
  // ),

  reportEmail: Yup.string().isvalidMail("The entered email address(s) are not valid").required('Report email is required'),

  totalDomains: Yup.number(),
  totalEmails: Yup.number(),
  emails: Yup.array().of(Yup.string().email(({ value }) => `${value} is not a valid email`)),
  domains: Yup.array().of(
    Yup.string().matches(
      /^(?!www)[A-Za-z0-9_-]+\.+[A-Za-z0-9./%&=?_:;-]+$/gm,
      ({ value }) => `${value} is not a valid domain`
    )
  ),
  // reportFrequency: Yup.string().oneOf(
  //   ['weekly', 'bi-weekly', 'monthly'],
  //   'Select one of the options'
  // ),
  // industry: Yup.string().oneOf(industries, 'Select one of the options'),
  // country: Yup.string().oneOf(countries, 'Select one of the options'),
})
