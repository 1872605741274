import MspMenu from '../../components/MspMenu'
import CustomersComponent from './CustomersComponent.jsx'
import { getMyMspService } from '../../services/msp.service'
import {
  bulkCreateCustomersService,
  getAllCustomersService,
  updateCustomerService,
} from '../../services/customer.service'
import { useState, useEffect } from 'react'
import { customersErrorHandler } from './errorHandler'
const csvToObj = require('csv-to-js-parser').csvToObj

const CustomersContainer = () => {
  const [showModal, setShowModal] = useState({})
  const [account, setAccount] = useState({})
  const [customers, setCustomers] = useState([])
  const [csvObjectCustomers, setCsvObjectCustomers] = useState(null)

  async function getMyMSPFromAPI() {
    try {
      const myMSP = await getMyMspService()
      setAccount(myMSP.data.data)
    } catch (error) {
      setShowModal(customersErrorHandler(error))
    }
  }

  async function getAllCustomersFromAPI() {
    try {
      const response = await getAllCustomersService()
      const customers = response.data.data

      // const thirtyDaysAgo = new Date()
      // thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

      // // Obtengo los lastcompromises
      // customers.map(customer => {
      //   customer.lastCompromises = customer.breaches.filter(
      //     breach => new Date(breach.spycloud_publish_date) > thirtyDaysAgo
      //   )
      //   return customer
      // })

      setCustomers(customers)
    } catch (error) {
      setShowModal(customersErrorHandler(error))
    }
  }

  useEffect(() => {
    getMyMSPFromAPI()
    getAllCustomersFromAPI()
  }, [])

  async function handleChangeCustomerState(customerId) {
    try {
      const customer = customers.find(cust => cust.customerId === customerId)
      await updateCustomerService({ status: !customer.status }, customerId)
      getAllCustomersFromAPI()
    } catch (error) {
      setShowModal(customersErrorHandler(error))
    }
  }

  async function handleChangeReportState(customerId) {
    try {
      const customer = customers.find(cust => cust.customerId === customerId)
      await updateCustomerService({ reportStatus: !customer.reportStatus }, customerId)
      getAllCustomersFromAPI()
    } catch (error) {
      setShowModal(customersErrorHandler(error))
    }
  }

  function showValidationError(error) {
    setShowModal({
      show: true,
      title: 'CSV UPLOAD',
      message: 'Please make sure that all required fields are completed.',
    })
  }

  function validateCustomers(customers) {
    let validationStatus = true
    customers.forEach(customer => {
      if (!customer['Company Name*']) {
        validationStatus = false
      } else if (!customer['First Name*']) {
        validationStatus = false
      } else if (!customer['Last Name*']) {
        validationStatus = false
      } else if (!customer['Customer Report Email Address*']) {
        validationStatus = false
      } else if (!customer['Domain 1*']) {
        validationStatus = false
      }
    })
    return validationStatus
  }

  function formatCustomersToShow(customers) {
    const formatedCustomers = customers.map(customer => {
      if (customer['Domain 2'] === 'undefined') delete customer['Domain 2']
      if (customer['Domain 3'] === 'undefined') delete customer['Domain 3']
      if (customer['Personal Email 1'] === 'undefined') delete customer['Personal Email 1']
      if (customer['Personal Email 2'] === 'undefined') delete customer['Personal Email 2']
      if (customer['Personal Email 3'] === 'undefined') delete customer['Personal Email 3']
      if (customer['Personal Email 4'] === 'undefined') delete customer['Personal Email 4']
      if (customer['Personal Email 5'] === 'undefined') delete customer['Personal Email 5']

      return customer
    })
    setCsvObjectCustomers(formatedCustomers)
  }

  function onChangeFile(e) {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.readAsText(file, 'UTF-8')
      reader.onload = function (evt) {
        try {
          const customers = csvToObj(evt.target.result, ',')
          if (validateCustomers(customers)) {
            formatCustomersToShow(customers)
          } else {
            showValidationError()
          }
        } catch (error) {
          const errorMessage = error.message
          if (errorMessage === "Cannot read properties of undefined (reading 'length')") {
            setShowModal({
              show: true,
              title: 'CSV UPLOAD',
              message: 'The CSV that you are uploading has no data.',
            })
          }
        }
      }
    }
  }

  function formatCustomersToLoad() {
    return csvObjectCustomers.map(customer => {
      const newCustomer = {
        companyName: customer['Company Name*'],
        firstName: customer['First Name*'],
        lastName: customer['Last Name*'],
        reportEmail: customer['Customer Report Email Address*'],
        domains: [customer['Domain 1*']],
        emails: [],
      }

      if (customer['Domain 2']) newCustomer.domains.push(customer['Domain 2'])
      if (customer['Domain 3']) newCustomer.domains.push(customer['Domain 3'])
      if (customer['Personal Email 1']) newCustomer.emails.push(customer['Personal Email 1'])
      if (customer['Personal Email 2']) newCustomer.emails.push(customer['Personal Email 2'])
      if (customer['Personal Email 3']) newCustomer.emails.push(customer['Personal Email 3'])
      if (customer['Personal Email 4']) newCustomer.emails.push(customer['Personal Email 4'])
      if (customer['Personal Email 5']) newCustomer.emails.push(customer['Personal Email 5'])

      return newCustomer
    })
  }

  function validateBeforeToLoad(customers) {
    let errors = []
    customers.forEach(customer => {
      customer.domains.forEach(domain => {
        if (
          !/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/.test(domain)
        ) {
          errors.push(`"${domain}" is not a valid domain for the customer ${customer.companyName}`)
        }
      })

      customer.emails.forEach(email => {
        if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
          errors.push(`"${email}" is not a valid email for the customer ${customer.companyName}`)
        }
      })

      if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(customer.reportEmail)) {
        errors.push(
          `The report email is not a valid email for the customer ${customer.companyName}`
        )
      }
    })

    if (errors.length > 0) {
      setCsvObjectCustomers(null)
      setShowModal({
        show: true,
        title: 'THE CSV HAS ERRORS',
        message: errors.map(error => (
          <>
            • {error}
            <br />
          </>
        )),
      })
      return false
    }

    return true
  }

  async function handleClickLoadCsvCustomers() {
    try {
      const customers = formatCustomersToLoad()

      if (validateBeforeToLoad(customers)) {
        await bulkCreateCustomersService(customers)
        getAllCustomersFromAPI()
        setCsvObjectCustomers(null)
        setShowModal({
          show: true,
          title: 'CREATED SUCCESSFULLY',
          message: 'All customers have been successfully created',
        })
      }
    } catch (error) {
      setShowModal(customersErrorHandler(error))
    }
  }

  function handleCloseCustomerUpload() {
    setCsvObjectCustomers(null)
  }

  const icons =
  Object.keys(account).length !== 0
    ? {
        domains: `${account.domains.length}/${account.package.packageDomains}`,
      }
    : {
        domains: '...',
      }


  function handleCloseModal() {
    setShowModal({})
  }

  return (
    <>
      <MspMenu>
        <CustomersComponent
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          icons={icons}
          customers={customers}
          handleChangeCustomerState={handleChangeCustomerState}
          onChangeFile={onChangeFile}
          csvObjectCustomers={csvObjectCustomers}
          handleClickLoadCsvCustomers={handleClickLoadCsvCustomers}
          handleCloseCustomerUpload={handleCloseCustomerUpload}
          handleChangeReportState={handleChangeReportState}
        />
      </MspMenu>
    </>
  )
}

export default CustomersContainer
