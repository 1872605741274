import React from 'react'
import './MdwMenu.scss'
import SecureIcon from '../../assets/icons/secure'
import PeopleIcon from '../../assets/icons/people'
import StarIcon from '../../assets/icons/star'
import NewsIcon from '../../assets/icons/news'
import ErrorIcon from '../../assets/icons/error'
import TrailIcon from '../../assets/icons/trail'
import IdIcon from '../../assets/icons/id'
import AlertIcon from '../../assets/icons/alert'
import LogoutIcon from '../../assets/icons/logout'
import ReportsIcon from '../../assets/icons/reports'
import PackagesIcon from '../../assets/icons/packages'
import AdminIcon from '../../assets/icons/admin'
import TwoFAResetIcon from '../../assets/icons/twoFAReset'

import { useState, useEffect } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { adminRoutes, routes } from '../../config/constants'
import { getMyUserService } from '../../services/user.service'
import Navbar from '../Navbar'
import Logo from '../../assets/img/MSP_Logo_LIGHT.svg'
import { logoutService } from '../../services/auth.service'

const MdwMenu = ({ children }) => {
  const [loggedOut, setLoggedOut] = useState(false)
  const [account, setAccount] = useState({})

  useEffect(() => {
    async function getMyUserFromAPI() {
      try {
        const response = await getMyUserService()
        setAccount(response.data.data)
      } catch (error) {
        console.error(error)
      }
    }
    getMyUserFromAPI()
  }, [])

  async function logout() {
    await logoutService()
    localStorage.clear()
    setLoggedOut(true)
  }

  return (
    <>
      {loggedOut ? (
        <Redirect to={routes.LOGIN_ROUTE} />
      ) : (
        <>
          <Navbar logo={Logo} />
          <div className="mdw-menu-sidebar">
            <div className="mdw-menu-title">
              <p>DARK WEB ADMIN</p>
              <span onClick={logout} className="mdw-menu-logout-button">
                <LogoutIcon />
              </span>
            </div>
            <div className="mdw-menu-options">
              <div className="mdw-menu-option">
                <Link to={adminRoutes.MSPS_ROUTE}>
                  <div>
                    <SecureIcon />
                    <span className="text text-nowrap">MSPs</span>
                  </div>
                </Link>
              </div>
              <div className="mdw-menu-option">
                <Link to={adminRoutes.PACKAGES_ROUTE}>
                  <div>
                    <PackagesIcon />
                    <span className="text text-nowrap">Packages</span>
                  </div>
                </Link>
              </div>
              <div className="mdw-menu-option">
                <Link to={adminRoutes.REPORTS_ROUTE}>
                  <div>
                    <ReportsIcon />
                    <span className="text text-nowrap">Reports</span>
                  </div>
                </Link>
              </div>
              <div className="mdw-menu-option">
                <Link to={adminRoutes.FEATURES_ROUTE}>
                  <div style={{ justifyContent: 'space-between' }}>
                    <div>
                      <StarIcon />
                      <span className="text text-nowrap">Feature Requests</span>
                    </div>
                    {account.badge?.featureRequests > 0 && (
                      <span className="badge badge-pill badge-danger">
                        {account.badge?.featureRequests}
                      </span>
                    )}
                  </div>
                </Link>
              </div>
              <div className="mdw-menu-option">
                <Link to={adminRoutes.NEWS_ROUTE}>
                  <div>
                    <NewsIcon />
                    <span className="text text-nowrap">News</span>
                  </div>
                </Link>
              </div>
              <div className="mdw-menu-option">
                <Link to={adminRoutes.BREACH_CATALOGUE_ROUTE}>
                  <div>
                    <ErrorIcon />
                    <span className="text text-nowrap">Breach Catalogue</span>
                  </div>
                </Link>
              </div>
              <div className="mdw-menu-option">
                <Link to={adminRoutes.SUBSCRIPTIONS_ROUTE}>
                  <div style={{ justifyContent: 'space-between' }}>
                    <div>
                      <TrailIcon />
                      <span className="text text-nowrap">Subscriptions</span>
                    </div>
                    {account.badge?.subscriptions > 0 && (
                      <span className="badge badge-pill badge-danger">
                        {account.badge?.subscriptions}
                      </span>
                    )}
                  </div>
                </Link>
              </div>
              <div className="mdw-menu-option">
                <Link to={adminRoutes.PENDING_MSPS_ROUTE}>
                  <div style={{ justifyContent: 'space-between' }}>
                    <div>
                      <IdIcon />
                      <span className="text text-nowrap">Pending MSPs</span>
                    </div>
                    {account.badge?.pendingMsps > 0 && (
                      <span className="badge badge-pill badge-danger">
                        {account.badge?.pendingMsps}
                      </span>
                    )}
                  </div>
                </Link>
              </div>
              <div className="mdw-menu-option">
                <Link to={adminRoutes.USERS_ROUTE}>
                  <div>
                    <PeopleIcon />
                    <span className="text text-nowrap">Users</span>
                  </div>
                </Link>
              </div>
              <div className="mdw-menu-option">
                <Link to={adminRoutes.ADMIN_ROUTE}>
                  <div>
                    <AdminIcon />
                    <span className="text text-nowrap">Admin</span>
                  </div>
                </Link>
              </div>
              <div className="mdw-menu-option">
                <Link to={adminRoutes.TWOFA_REQUESTS_ROUTE}>
                  <div>
                    <TwoFAResetIcon />
                    <span className="text text-nowrap">2FA Reset Request</span>
                  </div>
                </Link>
              </div>
            </div>
            <div className="mdw-menu-footer">
              <p className="footer-title">LOGGED IN USER</p>
              <p className="footer-username">{account.username}</p>
            </div>
          </div>
          <div className="content">{children}</div>
        </>
      )}
    </>
  )
}

export default MdwMenu
